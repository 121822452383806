<template>
  <div style="width: 100%">
    <el-form
      ref="postForm"
      label-position="right"
      :model="postData"
      :rules="rules"
      size="medium"
      @submit.native.prevent="handleSubmit">
      <el-form-item prop="text" :label="$props.title">
        <el-input type="textarea" v-model="postData.text" maxlength="32"></el-input>
      </el-form-item>

      <el-form-item class="admin-dialog-btns">
        <el-button type="primary" native-type="submit">提交</el-button>
        <el-button @click="cancel">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
  },
  data() {
    return {
      postData: {
        text: '',
      },
      rules: {
        text: [this.$rules.required],
      },
    };
  },
  methods: {
    cancel() {
      this.$emit('fail');
      this.$emit('end');
    },
    handleSubmit() {
      this.$refs['postForm'].validate((valid) => {
        if (valid) {
          this.$emit('success', this.postData.text);
          this.$emit('end');
        }
      });
    },
  },
};
</script>

<style></style>
