<template>
  <el-date-picker
    style="width: 100%"
    v-model="date"
    @change="changeHanlder"
    value-format="timestamp"
    v-bind.prop="$attrs"
    v-on="$listeners"></el-date-picker>
</template>

<script>
export default {
  created() {},
  props: {},
  data() {
    return {
      date: '',
    };
  },
  watch: {
    value: {
      handler: function (d) {
        this.date = d * 1000;
      },
      immediate: true,
    },
  },
  methods: {
    changeHanlder() {
      this.$emit('input', parseInt(this.date / 1000));
    },
  },
};
</script>

<style></style>
