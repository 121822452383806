import Vue from 'vue';
import hasPermission from '@/utils/permission';
import highlight from './highlight';
import onlyNumber from './only-number';
Vue.directive('p', {
  // 当被绑定的元素插入到 DOM 中时……
  inserted: computePermission,
  // 当被绑定的元素更新时……
  update: computePermission,
});

function computePermission(el, options) {
  let domPermissionList = options.value;
  if (!hasPermission(domPermissionList)) {
    el.remove();
  }
}
Vue.directive('highlight', highlight);
Vue.directive('onlyNumber', onlyNumber);
