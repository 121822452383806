// import {
//   setAreaRange
// } from "@/utils";

const LOCAL_STORAGE_KEY = 'admin-ui-info-bx-template'; // localStorage 的 key
let storageTemplate;
try {
  storageTemplate = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY));
} catch (err) {
  console.warn(err);
  storageTemplate = {};
}
const state = {
  templateType: '',
  templateData: [],
  templateDict: {
    A1Price: '',
  },
  applyPrice: 0,

  //...
};
void storageTemplate;

export default {
  namespaced: true,
  state: Object.assign(state, storageTemplate),
  mutations: {
    setTemplateData(state, templateData) {
      Object.assign(state, templateData);
      localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(templateData));
    },
  },
};
