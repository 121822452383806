import axios from 'axios';
import {Message} from 'element-ui';
import store from '@/store/index';
import dict from '@/utils/dict';
// import utils from '@/utils/utils';

//获取用户token，如果没有，跳转到登录页面
// 创建axios实例

const ajax = axios.create({
  baseURL: dict.SETTING.API_URL,
});
// 添加请求拦截器
ajax.interceptors.request.use(
  function (config) {
    // var debug = utils.getUrlParams('debug');
    // config.baseURL = debug ? debug : dict.SETTING.API_URL;
    config.headers['authorization'] = store.getters['user/token'];
    return config;
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  },
);
// 添加响应拦截器
ajax.interceptors.response.use(
  function (response) {
    if (response.data.code == 2) {
      store.dispatch('user/logout').then(() => {
        Message({
          message: '登录超时',
          type: 'info',
          duration: 5 * 1000,
        });
        location.reload();
      });
    }
    if (response.data.code == 3) {
      Message({
        message: '无权限！',
        type: 'warning',
        duration: 5 * 1000,
      });
    }
    return response;
  },
  function (error) {
    console.warn('请求错误', error); // for debug
    // Message({
    //   message: error.message,
    //   type: 'error',
    //   duration: 5 * 1000,
    // });
    return Promise.reject(error);
  },
);

export default ajax;
