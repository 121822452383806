import Vue from 'vue';
import Element from 'element-ui';
//解决element-ui 冻结列滚动延迟问题
import '@/utils/element-table-bug';
Vue.use(Element);

//拦截 $message
let originFuncSuccess = Vue.prototype.$message.success;
Vue.prototype.$message.success = function (msg) {
  originFuncSuccess({
    message: msg,
    showClose: true,
  });
};

let originFuncError = Vue.prototype.$message.error;
Vue.prototype.$message.error = function (msg) {
  originFuncError({
    message: msg,
    showClose: true,
  });
};
//导入css
import 'normalize.css';
import './style/element-variables.scss';
import '@/style/admin.scss';
//装载指令
import './directive/install';
//装载filter
import '@/filter/install';
//装载utils
import '@/utils/install';
//装载组件
import '@/components/install';

import '@/components/admin-confirm.js';
import '@/components/admin-confirm-input.js';
import App from './app.vue';
import store from './store';
import {router} from './router';
window.app = new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
if (process.env.NODE_ENV != 'production') {
  document.body.appendChild(
    new Vue({
      ...require('./debug.vue').default,
    }).$mount().$el,
  );
}
window.Vue = Vue;
