import Vue from 'vue';
import Vuex from 'vuex';
import user from './modules/user';
import template from './modules/template';
import dot from './modules/dot';
Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    user,
    template,
    dot,
  },
});
