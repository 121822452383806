import store from '@/store';
import dict from '@/utils/dict';
export default function hasPermission(userPermission) {
  if (!dict.SETTING.OPEN_PERMISSION || dict.SETTING.IS_MGT) {
    //是否开启权限
    return true;
  }
  if (store.getters['user/isSuperAdmin']) {
    //是否为超管
    return true;
  }

  userPermission = userPermission.map((item) => item.toString());
  let _hasPermission = false;
  _hasPermission = store.state.user.permissionId.some((role) => {
    return userPermission.includes(role.toString());
  });
  return _hasPermission;
}
