import basicLayout from '@/layout/basic-layout';

import login from '@/views/login';
//eslint-disable-next-line
const RouteView = {
  name: 'RouteView',
  render: (h) => h('router-view'),
};
//eslint-disable-next-line
const KeepView = {
  name: 'KeepView',
  render: (h) => h('keep-alive', [h('router-view')]),
};
//eslint-disable-next-line
const tagView = require('@/components/tag-view.vue').default;

/**
 * 所有路由中的path 必须为 fullpath 即相对于根目录的绝对路径
 * meta
 *   icon {string} - 栏目图标的class
 *   name {string} - 栏目名称
 *   hide {boolean} - 是否隐藏 （导航）
 *   keep {boolean} - 是否缓存
 *   hideChildren {boolean} -是否隐藏子栏目
 *   p {array} - 权限白名单
 */
let rootRoutes = {
  path: '/',
  redirect: '/home',
  component: basicLayout,
  children: [
    {
      path: '/home',
      name: 'home',
      meta: {
        name: '概览',
        icon: 'iconfont el-icon-s-platform',
      },
      component: () => import('@/views/home/home.vue'),
    },
    {
      path: '/student',
      component: () => import('@/views/student/student.vue'),
      meta: {
        name: '学员列表',
        icon: 'iconfont el-icon-s-custom',
      },
    },
    // {
    //   path: '/exam',
    //   component: () => import('@/views/exam/exam.vue'),
    //   meta: {
    //     name: '考试信息',
    //     icon: 'iconfont el-icon-s-data',
    //   },
    // },
    {
      path: '/compensation',
      component: () => import('@/views/compensation/compensation.vue'),
      meta: {
        name: '服务补偿',
        icon: 'iconfont el-icon-s-check',
      },
    },
    {
      path: '/policy',
      component: () => import('@/views/policy/policy.vue'),
      meta: {
        name: '服务对账单',
        icon: 'iconfont el-icon-menu',
        hasDot: true,
      },
    },
    {
      path: '/balance-detail',
      component: () => import('@/views/balance-detail/balance-detail.vue'),
      meta: {
        name: '余额变更明细',
        icon: 'iconfont el-icon-s-promotion',
      },
    },
    {
      path: '/system',
      component: () => import('@/views/system/system.vue'),
      meta: {
        name: '系统设置',
        icon: 'iconfont el-icon-s-tools',
      },
    },

    // {
    //   path: '/system',
    //   component: RouteView,
    //   meta: {
    //     p: ['sys:admin', 'sys:role', 'sys:log'],
    //     name: '系统管理',
    //     icon: 'iconfont icon_setup_normal',
    //   },
    //   children: [
    //     {
    //       path: '/manage/system/user',
    //       component: () => import('@/views/system/user/user.vue'),
    //       name: 'user',
    //       meta: {
    //         name: '管理员帐号',
    //       },
    //     },
    //   ],
    // },
  ],
};
export {rootRoutes};
const routes = [
  {
    path: '/403',
    name: '403',
    meta: {
      tag: false,
      hide: true,
    },
    component: () => import('@/views/page-403.vue'),
  },
  {
    path: '*',
    meta: {
      tag: false,
      hide: true,
    },
    component: () => import('@/views/page-404.vue'),
  },
  {
    path: '/empty',
    meta: {
      hide: true,
    },
    component: basicLayout,
    children: [
      {
        path: '',
        name: 'empty',
        meta: {
          tag: false,
        },
        component: () => import('@/views/page-empty.vue'),
      },
    ],
  },
  {
    path: '/login',
    name: 'login',
    component: login,
    meta: {
      hide: true,
    },
  },
  rootRoutes,
];

export const routeMap = new Map(); // key<Symbol>:permission<array>
window.routeMap = routeMap;
generateRouteMap(routes);

function generateRouteMap(routes) {
  routes.map((route) => {
    route.meta = route.meta || {};
    route.meta.key = Symbol();
    routeMap.set(route.meta.key, route);
    if (route.children && route.children.length) {
      generateRouteMap(route.children);
    }
  });
}

export default routes;
