/**
 * 安装全局自定义的vue组件
 */
import Vue from 'vue';
import selectDict from './select/select-dict.vue';
import selectDictRemote from './select/select-dict-remote.vue';
import selectArea from './select/select-area.vue';
import inputIp from './input-ip.vue';
import inputMac from './input-mac.vue';
import inputPort from './input-port.vue';
import searchContainer from './search-container.vue';
import adminDialog from './admin-dialog/admin-dialog';
import adminSpace from './space/index.js';
import treeSelect from './tree-select.vue';
import dateTimeRange from './date-time-range';
import dateTime from './date-time';
import adminLoading from './admin-loading/index';
Vue.prototype.$adminLoading = adminLoading;
Vue.prototype.$adminDialog = adminDialog;
Vue.use({
  install: function (Vue) {
    Vue.component('selectDict', selectDict);
    Vue.component('selectDictRemote', selectDictRemote);
    Vue.component('selectArea', selectArea);
    Vue.component('inputIp', inputIp);
    Vue.component('inputMac', inputMac);
    Vue.component('inputPort', inputPort);
    Vue.component('searchContainer', searchContainer);
    Vue.component('adminSpace', adminSpace);
    Vue.component('treeSelect', treeSelect);
    Vue.component('dateTimeRange', dateTimeRange);
    Vue.component('dateTime', dateTime);
  },
});
