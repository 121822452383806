import Vue from 'vue';
let com = require('./admin-confirm-input.vue').default;
Vue.prototype.$adminConfirmInput = adminConfirm;
function adminConfirm(props) {
  return new Promise((resolve, reject) => {
    let vNode = this.$createElement(com, {
      key: Math.random(),
      props: props,
      on: {
        success: (text) => {
          resolve(text);
        },
        fail() {
          reject();
        },
      },
    });

    this.$adminDialog(vNode, {
      title: '提示',
      width: '400px',
    });
  });
}
