import Vue from 'vue';
import utils from '@/utils/utils';
import Decimal from 'decimal.js';
Vue.filter('date', function (value) {
  if (!value) return '';
  return utils.formatDate(value);
});

Vue.filter('fullDate', function (value) {
  if (!value) return '';
  return utils.formatDateFull(value);
});
Vue.filter('price', function (value, emptyVal) {
  if (value === undefined || value === '') {
    return emptyVal || '';
  }
  return '￥' + Decimal(value).toFixed(2);
});
Vue.filter('decimal', function (value) {
  if (value == undefined) {
    return '';
  }
  return Decimal(value).toFixed(2);
});
Vue.filter('humanSize', function (value, unit, decimal = 0) {
  return utils.humanSize(value, unit, decimal);
});
Vue.filter('humanSizeObj', function (value, unit, prop, decimal) {
  let result = utils.humanSizeObj(value, unit, decimal);

  if (prop) {
    return result[prop];
  }
});
