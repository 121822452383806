import Vue from 'vue';
import {Dialog, Drawer} from 'element-ui';
let AdminBoxConstructor = Vue.extend(Dialog);
let DrawerBoxConstructor = Vue.extend(Drawer);
import store from '@/store';
import {router} from '@/router';
/**
 *
 * @param {vNode} vNode -vue中的vNode对象
 * @param {object} options
 * @param options.type - dialog/drawer
 * -element-ui中dialog的 Attributes 详见：https://element.eleme.cn/#/zh-CN/component/dialog
 * - element-ui中dialog的 Attributes 详见https://element.eleme.cn/#/zh-CN/component/drawer
 */
function adminBox(vNode, options) {
  var that = this;

  function beforeDestroyCall() {
    instance.$emit('update:visible', false);
  }
  try {
    this.$options.beforeDestroy = this.$options.beforeDestroy || [];
    this.$options.beforeDestroy.push(beforeDestroyCall);
  } catch (err) {
    console.warn(err);
  }
  let comp = options.type == 'drawer' ? DrawerBoxConstructor : AdminBoxConstructor;
  let instance = new comp({
    router,
    store,
    data: {},
  });

  options.customClass = options.customClass || '';
  instance.closeOnClickModal = false;
  Object.assign(instance, options, {
    customClass: options.customClass + ' admin-dialog',
  });
  vNode.componentOptions.listeners = vNode.componentOptions.listeners || {};
  let listeners = vNode.componentOptions.listeners;

  instance.$on('close', function () {
    instance.visible = false;
    listeners.end();
  });
  instance.$on('closed', function () {
    instance.$destroy();
    instance.$el.remove();
    instance = null;
    //删除组件销毁的回调
    let index = that.$options.beforeDestroy.indexOf(beforeDestroyCall);
    if (index != -1) {
      that.$options.beforeDestroy.splice(index, 1);
    }
  });

  let end = listeners.end;
  for (let eventName in options.on) {
    instance.$on(eventName, options.on[eventName]);
  }

  listeners.end = function (data) {
    instance.visible = false;
    instance.$emit('update:visible', false);
    if (typeof end === 'function') {
      end(data);
    }
  };
  instance.$slots.default = [vNode];
  instance.vm = instance.$mount();
  document.body.appendChild(instance.vm.$el);
  instance.vm.$nextTick(() => {
    //保留动画效果
    instance.visible = true;
  });

  return instance;
}

export default adminBox;
