import Hljs from 'highlight.js';
import 'highlight.js/styles/tomorrow-night.css'; // 代码高亮风格，选择更多风格需导入 node_modules/hightlight.js/styles/ 目录下其它css文件

export default {
  deep: true,
  bind(el, binding) {
    // on first bind, highlight all targets
    let targets = el.querySelectorAll('code');

    targets.forEach((target) => {
      if (typeof binding.value === 'string') {
        // if a value is directly assigned to the directive, use this
        // instead of the element content.
        target.textContent = binding.value;
      }
      Hljs.highlightBlock(target);
    });
  },
  componentUpdated(el, binding) {
    // after an update, re-fill the content and then highlight
    let targets = el.querySelectorAll('code');

    targets.forEach((target) => {
      if (typeof binding.value === 'string') {
        // if a value is directly assigned to the directive, use this
        // instead of the element content.
        target.textContent = binding.value;
        Hljs.highlightBlock(target);
      }
    });
  },
};
