// import {
//   setAreaRange
// } from "@/utils";

const LOCAL_STORAGE_KEY = 'admin-ui-info-bx-school'; // localStorage 的 key
let storageUser;
try {
  storageUser = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY));
} catch (err) {
  console.warn(err);
  storageUser = {};
}
const state = {
  userType: '',
  drvSchoolCode: '',
  realName: '',
  mobile: '',
  token: '',
  permissionId: [],
  //...
};
void storageUser;

export default {
  namespaced: true,
  state: Object.assign(state, storageUser),
  mutations: {
    setUserData(state, userData) {
      Object.assign(state, userData);
      localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(userData));
    },
  },
  actions: {
    /**
     * 注销
     * @returns {Promise} -返回promise 为了兼容异步
     */
    logout() {
      return new Promise((resolve) => {
        localStorage.removeItem(LOCAL_STORAGE_KEY);
        localStorage.removeItem('admin-ui-info-bx-template');
        resolve();
      }).catch((err) => {
        console.warn(err);
      });
    },
  },
  getters: {
    token(state) {
      return state.token;
    },
    isLogin(state) {
      return !!state.token;
    },
    isSuperAdmin() {
      return true;
      // return state.userId == 1;
    },
  },
};
