<template>
  <div class="ip-input">
    <el-input
      :disabled="disabled"
      v-model="arr[0]"
      @keyup.native="
        (event) => {
          keyupHanlder(0, event);
        }
      "
      @input="inputHanlder(0)"
      ref="ip0"
      maxlength="3"
      :validate-event="false"
      @paste.native="paste"></el-input>
    <div class="separator">.</div>
    <el-input
      :disabled="disabled"
      v-model="arr[1]"
      @keyup.native="
        (event) => {
          keyupHanlder(1, event);
        }
      "
      @input="inputHanlder(1)"
      @paste.native="paste"
      ref="ip1"
      maxlength="3"
      :validate-event="false"></el-input>
    <div class="separator">.</div>
    <el-input
      :disabled="disabled"
      v-model="arr[2]"
      @keyup.native="
        (event) => {
          keyupHanlder(2, event);
        }
      "
      @input="inputHanlder(2)"
      @paste.native="paste"
      ref="ip2"
      maxlength="3"
      :validate-event="false"></el-input>
    <div class="separator">.</div>

    <el-input v-if="$props.endNum" disabled :value="$props.endNum"></el-input>
    <el-input
      v-else
      :disabled="disabled || disabledEnd"
      v-model="arr[3]"
      @keyup.native="
        (event) => {
          keyupHanlder(3, event);
        }
      "
      @input="inputHanlder(3)"
      @paste.native="paste"
      ref="ip3"
      maxlength="3"
      :validate-event="false"></el-input>
  </div>
</template>

<script>
export default {
  props: {
    //默认值
    value: String,
    disabled: Boolean,
    disabledEnd: Boolean,
    endNum: String,
  },
  data() {
    return {
      arr: [],
    };
  },
  watch: {
    value: function () {
      this.arr = this.$props.value.split('.');
    },
    arr: function (newVal) {
      this.$emit(
        'input',
        newVal
          .filter((item) => !!item)
          .slice(0, 4)
          .join('.'),
      );
    },
  },
  created() {
    if (this.$props.value) {
      this.arr = this.$props.value.split('.');
      if (this.$props.endNum != undefined) {
        this.arr[4] = this.$props.endNum;
      }
    }
    if (this.$props.endNum) {
      this.arr[3] = 0;
    }
  },
  methods: {
    paste(event) {
      let paste = (event.clipboardData || window.clipboardData).getData('text');
      if (paste && /^((2[0-4]\d|25[0-5]|[01]?\d\d?)\.){3}(2[0-4]\d|25[0-5]|[01]?\d\d?)$/.test(paste)) {
        setTimeout(() => {
          this.arr = paste.split('.');
        });
      }
    },
    inputHanlder(index) {
      this.arr[index] = this.arr[index].replace(/\./g, '');
      let nextInput = this.$refs['ip' + (index + 1)];
      if (this.arr[index].length == 3 && nextInput) {
        nextInput.select();
        nextInput.focus();
      }
    },
    keyupHanlder(index, event) {
      if (!['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', '.'].includes(event.key)) {
        return false;
      }
      if (!this.arr[index]) {
        return false;
      }
      let nextInput = this.$refs['ip' + (index + 1)];
      if (event.key == '.' && nextInput) {
        nextInput.select();
        nextInput.focus();
      }
    },
  },
};
</script>

<style lang="scss">
.ip-input {
  display: flex;
  justify-content: space-between;
  .separator {
    width: 4em;
    text-align: center;
  }
  .el-input {
  }
}
</style>
