<template>
  <select-dict v-bind="$attrs" v-on="$listeners" :dict="dict" v-if="dict.length"></select-dict>
  <select-dict :dict="[]" v-else></select-dict>
</template>

<script>
export default {
  data() {
    return {
      dict: [],
    };
  },
  props: {
    api: {
      type: Function,
      required: true,
    },
    query: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  watch: {
    query: {
      handler: function () {
        // this.getData();
      },
      deep: true,
    },
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      this.$props.api(this.$props.query).then((res) => {
        let dict = [];
        if (res.data.code == 0) {
          dict = res.data.data;
        }
        this.$set(this.$data, 'dict', dict);
      });
    },
  },
};
</script>

<style></style>
