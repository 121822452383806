export default {
  namespaced: true,
  state: {
    unfinishedTotal: 0,
  },
  mutations: {
    setState(state, obj) {
      state.unfinishedTotal = obj.unfinishedTotal;
    },
  },
  actions: {},
  getters: {},
};
