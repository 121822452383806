<template>
  <div class="input-port">
    <div class="range-port" v-if="portType == 2">
      <el-input
        v-model="port"
        v-only-number="{max: 65535, min: 0, precision: 0}"
        :validate-event="false"
        @change="handleChange"
        @input="handleInput"
        @blur="handleBlur"></el-input>
    </div>

    <div v-if="portType == 1" class="range-port">
      <el-input
        v-model="portStart"
        v-only-number="{max: 65535, min: 0, precision: 0}"
        placeholder="起始端口"
        :validate-event="false"
        @change="handleChange"
        @input="handleInput"
        @blur="handleBlur"></el-input>
      <div style="padding: 0 6px">-</div>
      <el-input
        v-model="portEnd"
        v-only-number="{max: 65535, min: 0, precision: 0}"
        placeholder="结束端口"
        :validate-event="false"
        @change="handleChange"
        @input="handleInput"
        @blur="handleBlur"></el-input>
    </div>
    <el-select v-model="portType" :validate-event="false">
      <el-option :value="1" label="连续端口"></el-option>
      <el-option :value="2" label="不连续端口"></el-option>
    </el-select>
  </div>
</template>

<script>
import emitter from 'element-ui/src/mixins/emitter';
export default {
  mixins: [emitter],
  inject: {
    elForm: {
      default: '',
    },
    elFormItem: {
      default: '',
    },
  },
  props: {
    value: [String],
    disabled: Boolean,
    readonly: Boolean,
  },
  watch: {
    value() {
      this.setValue();
    },
  },
  data() {
    return {
      portType: 1,
      port: '',
      portStart: '',
      portEnd: '',
    };
  },
  created() {
    this.setValue();
  },
  methods: {
    handleBlur(event) {
      this.$emit('blur', event);
      if (this.validateEvent) {
        this.dispatch('ElFormItem', 'el.form.blur', [this.value]);
      }
    },
    handleInput() {
      //   console.log(this.getValue());
      this.$emit('input', this.getValue());
    },
    handleChange() {
      this.$emit('change', this.getValue());
    },
    getValue() {
      if (this.portType == 1) {
        return [this.portStart, this.portEnd].join('-');
      } else {
        return this.port;
      }
    },
    setValue() {
      if (this.getValue() == this.$props.value) {
        return false;
      }
      let port = this.$props.value.split('-');
      if (port.length == 2) {
        this.portType = 1;
        this.port = '';
        this.portStart = port[0];
        this.portEnd = port[1];
      } else {
        this.portType = 2;
        this.port = port[0];
        this.portStart = '';
        this.portEnd = '';
      }
    },
  },
};
</script>

<style lang="scss">
.input-port {
  display: flex;

  .el-input {
  }
  .el-select {
    padding-left: 12px;
    width: 140px;
    flex-shrink: 0;
  }
  .range-port {
    display: flex;
  }
}
</style>
