import {Decimal} from 'decimal.js';
/**
 * 用法
 *   <el-input
                maxlength="50"
                v-model="tableQuery.minPrice"
                v-only-number="{max: 99999999, min: 0, precision: 2}"
              ></el-input>
 */
export default {
  update(el, vDir) {
    el.vDir = vDir;
  },
  // 当被绑定的元素插入到 DOM 中时……
  inserted(el, vDir) {
    let content;
    el.vDir = vDir;
    //按键按下=>只允许输入 数字/小数点
    el.addEventListener('keypress', (event) => {
      vDir = el.vDir;
      let e = event || window.event;
      let inputKey = String.fromCharCode(typeof e.charCode === 'number' ? e.charCode : e.keyCode);
      let re = /\d|\./;
      content = e.target.value;
      //定义方法,阻止输入
      function preventInput() {
        if (e.preventDefault) {
          e.preventDefault();
        } else {
          e.returnValue = false;
        }
        try {
          e.target.dispatchEvent(new Event('input', {bubbles: true, cancelable: true}));
        } catch (err) {
          console.log(err);
        }
      }
      if (!re.test(inputKey) && !e.ctrlKey) {
        preventInput();
      } else if (content.indexOf('.') > 0 && inputKey == '.') {
        //已有小数点,再次输入小数点
        preventInput();
      }
    });
    //按键弹起=>并限制最大最小
    // el.addEventListener('keyup', (event) => {
    //   vDir = el.vDir;
    //   let e = event || window.event;
    //   if (e.target.value == '') {
    //     return false;
    //   }
    //   content = parseFloat(e.target.value);
    //   if (!content) {
    //     content = 0.0;
    //   }
    //   let arg_max = '';
    //   let arg_min = '';
    //   if (vDir.value) {
    //     arg_max = parseFloat(vDir.value.max);
    //     arg_min = parseFloat(vDir.value.min);
    //   }
    //   if (arg_max && content > arg_max) {
    //     e.target.value = arg_max;
    //     try {
    //       e.target.dispatchEvent(new Event('input', {bubbles: true, cancelable: true}));
    //     } catch (err) {
    //       console.log(err);
    //     }
    //     content = arg_max;
    //   }
    //   if (arg_min && content < arg_min) {
    //     e.target.value = arg_min;
    //     try {
    //       e.target.dispatchEvent(new Event('input', {bubbles: true, cancelable: true}));
    //     } catch (err) {
    //       console.log(err);
    //     }
    //     content = arg_min;
    //   }
    // });
    //失去焦点=>保留指定位小数
    el.addEventListener('focusout', (event) => {
      vDir = el.vDir;
      //此处会在 el-input 的 @change 后执行
      let e = event || window.event;
      if (e.target.value == '') {
        return false;
      }
      content = parseFloat(e.target.value);
      if (!content) {
        content = 0.0;
      }
      let arg_max = '';
      let arg_min = '';
      if (vDir.value) {
        arg_max = parseFloat(vDir.value.max);
        arg_min = parseFloat(vDir.value.min);
      }
      if (arg_max && content > arg_max) {
        e.target.value = arg_max;
        try {
          e.target.dispatchEvent(new Event('input', {bubbles: true, cancelable: true}));
        } catch (err) {
          console.log(err);
        }
        content = arg_max;
      }
      if (arg_min && content < arg_min) {
        e.target.value = arg_min;
        try {
          e.target.dispatchEvent(new Event('input', {bubbles: true, cancelable: true}));
        } catch (err) {
          console.log(err);
        }
        content = arg_min;
      }
      let arg_precision = 0; //默认保留至整数
      if (vDir.value.precision) {
        arg_precision = parseFloat(vDir.value.precision);
      }
      e.target.value = Decimal(content).toFixed(arg_precision, Decimal.ROUND_DOWN);
      try {
        e.target.dispatchEvent(new Event('input', {bubbles: true, cancelable: true}));
      } catch (err) {
        console.log(err);
      }
    });
  },
};
