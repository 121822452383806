<!-- 
使用方法：
gutter对应el-row的gutter
span对应el-col的span
close-show 关闭状态下显示几个
show-button 是否显示展开按钮
open 是否展开
<search-container show-button :gutter="20" :span="6" :close-show="3">
    <el-form-item>
        <el-input></el-input>
    </el-form-item>
    <el-button slot="submit">查询</el-button>
</search-container>
 -->

<script>
export default {
  props: {
    showButton: Boolean,
    closeShow: {
      type: Number,
      default: 2,
    },
    span: {
      type: Number,
      default: 8,
    },
    open: Boolean, //是否展开
  },
  data() {
    return {
      isOpen: false,
    };
  },
  created() {
    this.isOpen = this.$props.open;
  },
  render() {
    let cols = [];
    if (this.$slots.default) {
      let limit = this.isOpen ? this.$slots.default.length : this.$props.closeShow;
      cols = this.$slots.default.slice(0, limit).map((slot) => {
        return <el-col span={this.$props.span}>{slot}</el-col>;
      });
    }
    let buttons = [];

    if (this.$props.showButton) {
      buttons.push(
        <el-link underline={false} type="primary" onclick={this.click} v-show={!this.isOpen}>
          展开 <i class="el-icon-arrow-down"></i>
        </el-link>,
      );
      buttons.push(
        <el-link underline={false} type="primary" onclick={this.click} v-show={this.isOpen}>
          收起 <i class="el-icon-arrow-up"></i>
        </el-link>,
      );
    }
    let row = (
      <el-row {...{props: this.$attrs}}>
        {cols}
        <el-col class="search-open" span={this.isOpen ? 24 : this.$props.span}>
          {this.$slots.submit}
          &#x3000;
          {buttons}
        </el-col>
      </el-row>
    );
    return row;
  },
  methods: {
    click() {
      this.isOpen = !this.isOpen;
    },
  },
};
</script>

<style></style>
