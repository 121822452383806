import Vue from 'vue';
let com = require('./admin-confirm.vue').default;
Vue.prototype.$adminConfirm = adminConfirm;
function adminConfirm(title) {
  return new Promise((resolve, reject) => {
    let vNode = this.$createElement(com, {
      key: Math.random(),
      props: {
        title: title,
      },
      on: {
        success: () => {
          resolve();
        },
        fail() {
          reject();
        },
      },
    });

    this.$adminDialog(vNode, {
      title: '提示',
      width: '400px',
    });
  });
}
