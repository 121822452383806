<template>
  <div class="admin-view-container-tag">
    <el-radio-group v-model="currentRoute" @change="changeRoute" class="admin-view-tag">
      <el-radio-button v-for="route in routeList" :label="route.name" :key="route.name">
        {{ route.meta.name }}
      </el-radio-button>
    </el-radio-group>
    <router-view :key="$route.fullPath"></router-view>
  </div>
</template>

<script>
import {routeMap} from '@/router/routes';

export default {
  data() {
    return {
      routeList: [],
      currentRoute: '',
    };
  },
  watch: {
    $route: function () {
      this.currentRoute = this.$route.name;
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      let parentRoute = routeMap.get(vm.$route.matched.slice(-2)[0].meta.key);
      vm.routeList = parentRoute.children;
      vm.currentRoute = vm.$route.name;
    });
  },
  // created() {
  //   let parentRoute = routeMap.get(this.$route.matched[1].meta.key);
  //   this.routeList = parentRoute.children;
  //   this.currentRoute = this.$route.name;
  // },
  methods: {
    changeRoute() {
      this.$router.push({name: this.currentRoute});
    },
  },
};
</script>

<style></style>
