<template>
  <div class="admin-login-container">
    <div class="header">
      驾考无忧
      <span class="line">|</span>
      <span style="font-size: 24px">驾校端</span>
    </div>

    <div class="admin-login">
      <div class="admin-login-banner">
        <div class="title">
          <div class="title-title">驾考无忧 - 驾校端</div>
          <div class="title-info">管理便捷 操作简单 高效省时</div>
        </div>
        <img src="/static/login.png" />
      </div>
      <div class="admin-login-form">
        <div class="title">登录</div>
        <div class="small-title">为保护企业的数据安全，请再次确认身份信息</div>
        <el-form :model="postData" :rules="rules" @submit.native.prevent="submit" ref="postForm">
          <el-form-item label="" prop="userName" style="margin-bottom: 40px">
            <el-input autocomplete="off" v-model="postData.userName" placeholder="请输入登录账号"></el-input>
          </el-form-item>
          <el-form-item label="" prop="passWord" style="margin-bottom: 40px">
            <el-input v-model="postData.passWord" type="password" placeholder="请输入登录密码"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button
              autocomplete="off"
              :loading="loading"
              type="primary"
              native-type="submit"
              class="admin-block-button login-btn">
              登录
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>

    <div class="bottom">
      <div class="bottom-top">
        建议您使用IE9+、FireFox、Google Chrome, 分辨率1280*800及以上浏览本网站，获得更好的用户体验。
      </div>
      <div class="bottom-bottom">
        版权所有©2022 安思创科驾考无忧 ｜ <span @click="openPage">冀ICP备2022026100号-2</span> |
        <img src="/static/gov-icon.png" alt="加载失败" style="vertical-align: middle; margin-right: 4px" />

        <span @click="openGovPage">冀公网安备13100202000726</span>
      </div>
    </div>
  </div>
</template>

<script>
import {sysUserLogin} from '@/api';
import md5 from '@/utils/md5.js';
export default {
  data() {
    return {
      loading: false,
      rules: {
        userName: [this.$rules.required],
        passWord: [this.$rules.required],
      },
      postData: {
        userName: '',
        passWord: '',
      },
    };
  },
  created() {},
  methods: {
    openPage() {
      window.open('https://beian.miit.gov.cn/#/Integrated/index', '_blank');
    },

    openGovPage() {
      window.open('https://beian.mps.gov.cn/#/query/webSearch?code=13100202000726/', '_blank');
    },

    submit() {
      this.$refs.postForm.validate((isValidate) => {
        if (isValidate) {
          this.loading = true;
          let postData = Object.assign({}, this.postData);
          postData.passWord = md5(postData.passWord, 32);
          sysUserLogin(postData)
            .then((res) => {
              if (res.data.code == 0) {
                if (res.data.data.userType != 2) {
                  this.$message.error('登陆错误');
                  return false;
                }
                let token = res.headers['authorization'];
                if (token) {
                  this.$store.commit('user/setUserData', {token: token, ...res.data.data});
                }

                this.$nextTick(() => {
                  this.$router.replace('/');
                });
              } else {
                this.$message.error(res.data.msg);
                return false;
              }
            })
            .catch((err) => {
              this.$message.error('登录错误');
              console.log(err);
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
  },
};
</script>

<style lang="scss">
.admin-login-container {
  width: 100%;
  height: 100%;
  background-attachment: fixed;
  background-size: cover;
  overflow: auto;
  .header {
    display: flex;
    align-items: center;
    font-size: 36px;
    color: #fff;
    height: 112px;
    background-image: url('/static/login-head.png');
    background-size: 100% 100%;
    box-sizing: border-box;
    padding-left: 360px;
    .line {
      margin: 0 18px;
    }
  }
  .admin-login {
    padding: 30px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 835px;
    padding: 121px 338px;
    box-sizing: border-box;
    background: linear-gradient(180deg, #d4ecff 0%, #74a7e2 100%);
  }
  .admin-login-banner {
    margin-top: -30px;
    overflow: hidden;
    height: 100%;
    .title {
      text-align: center;
      .title-title {
        font-size: 48px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
        color: #ffffff;
      }
      .title-info {
        font-size: 30px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
        color: #ffffff;
        margin-top: 20px;
      }
    }
    img {
      height: 75%;
    }
  }
  .login-btn {
    width: 360px;
    height: 58px;
    background: #3066aa;
    border-radius: 2px;
    font-size: 20px;
    font-family: SourceHanSansCN-Bold, SourceHanSansCN;
    font-weight: bold;
    color: #ffffff;
  }
  .admin-login-form {
    flex-shrink: 0;
    padding: 77px 50px 0;
    box-sizing: border-box;
    width: 457px;
    height: 516px;
    background: #ffffff;
    box-shadow: 2px 2px 20px 0px rgba(16, 33, 139, 0.2);
    border-radius: 10px;
    .title {
      text-align: center;
      color: #0c1935;
      font-size: 30px;
      font-weight: bold;
    }
    .small-title {
      text-align: left;
      font-size: 14px;
      color: #ff0000;
      margin: 50px 0 24px;
    }
    .el-input__inner {
      background: #f2f4f9;
      border-radius: 2px;
      height: 54px;
      line-height: 54px;
      font-size: 18px;
      color: #9ba0ac;
    }
  }
  .bottom {
    width: 100%;
    height: 133px;
    background: #366fb6;
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    text-align: center;
    box-sizing: border-box;
    padding: 40px 0;
    .bottom-bottom {
      margin-top: 20px;
      span {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }
}
</style>
