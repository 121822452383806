<template>
  <transition name="el-loading-fade" @after-leave="handleAfterLeave">
    <div
      v-show="visible"
      class="el-loading-mask"
      :style="{backgroundColor: background || ''}"
      :class="[customClass, {'is-fullscreen': fullscreen}]">
      <div class="el-loading-spinner">
        <div style="width: 100%; text-align: center" v-if="usePercent">
          <el-progress :percentage="percentage" style="width: 300px; margin: 0 auto"></el-progress>
        </div>
        <svg v-else class="circular" viewBox="25 25 50 50">
          <circle class="path" cx="50" cy="50" r="20" fill="none" />
        </svg>
        <p v-if="text" class="el-loading-text">{{ text }}</p>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  data() {
    return {
      usePercent: false,
      percentage: 0,
      text: null,
      spinner: null,
      background: null,
      fullscreen: true,
      visible: false,
      customClass: '',
    };
  },

  methods: {
    setPercentage(v) {
      this.usePercent = true;
      this.percentage = v;
    },
    handleAfterLeave() {
      this.$emit('after-leave');
    },
    setText(text) {
      this.text = text;
    },
  },
};
</script>
