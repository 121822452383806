<template>
  <div style="width: 100%">
    <el-form
      ref="postForm"
      label-position="right"
      :model="postData"
      :rules="rules"
      size="medium"
      @submit.native.prevent="handleSubmit">
      <el-form-item prop="text">
        <div>请在输入框内输入<span class="color-danger">确认</span>，{{ $props.title }}</div>
        <el-input v-model="postData.text" maxlength="32"></el-input>
      </el-form-item>

      <el-form-item class="admin-dialog-btns">
        <el-button type="primary" native-type="submit">提交</el-button>
        <el-button @click="cancel">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
  },
  data() {
    return {
      postData: {
        text: '',
      },
      rules: {
        text: [this.$rules.required],
      },
    };
  },
  methods: {
    cancel() {
      this.$emit('fail');
      this.$emit('end');
    },
    handleSubmit() {
      this.$refs['postForm'].validate((valid) => {
        if (valid) {
          if (this.postData.text == '确认') {
            this.$emit('success');
            this.$emit('end');
          } else {
            this.$message.error('请输入指定内容');
          }
        }
      });
    },
  },
};
</script>

<style></style>
