import Decimal from 'decimal.js';
import dict from './dict';
import utils from './utils';
export default {
  price(data, colData) {
    if (data[colData.property] == undefined) {
      return '';
    }
    return '¥' + Decimal(data[colData.property]).toFixed(2);
  },
  decimal(data, colData) {
    if (data[colData.property] == undefined) {
      return '';
    }
    return Decimal(data[colData.property]).toFixed(2);
  },
  dict(_dict) {
    return function (data, colData) {
      return dict.getValue(_dict, data[colData.property]);
    };
  },
  date(isFull, format) {
    format = format || null;
    return function (data, colData) {
      if (isFull) {
        return utils.formatDateFull(data[colData.property]);
      } else {
        return utils.formatDate(data[colData.property], format);
      }
    };
  },
  areaNameFull(spliter = '-') {
    //spliter 分隔符
    return function (data, colData) {
      let resultArr = utils.getAreaNameFull(data[colData.property]);
      if (resultArr) {
        return resultArr.join(spliter.toString());
      }
      return '';
    };
  },
};
