import Vue from 'vue';
import utils from '@/utils/utils';
import dict from '@/utils/dict';
import rules from '@/utils/rules';
import formatter from '@/utils/formatter';
import hasPermission from '@/utils/permission';

Vue.prototype.$hasPermission = hasPermission;
Vue.prototype.$utils = utils;
Vue.prototype.$dict = dict;
Vue.prototype.$rules = rules;
Vue.prototype.$formatter = formatter;
