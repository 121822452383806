const dict = {
  //全局字典
  SETTING: {
    OPEN_PERMISSION: true, //是否开启权限
    API_URL: '/api/', //API具体路径
    IMG_URL: '/api/image/',
    AREA_CODE: 131000,
  },
  auditStatus: [
    {value: '1', label: '待审核', labelShow: '处理中'},
    {value: '2', label: '通过', labelShow: '已缴费'},
    {value: '3', label: '未通过', labelShow: '缴费失败'},
  ],
  compensateApplyStatus: [
    {value: '1', label: '待审核'},
    {value: '2', label: '审核中'},
    {value: '3', label: '已通过'},
    {value: '4', label: '未通过'},
  ],
  passStatus: [
    {value: '1', label: '合格'},
    {value: '2', label: '不合格'},
    {value: '3', label: '其他'},
  ],
  vehicleType: [
    {value: 'A1', label: 'A1'},
    {value: 'A2', label: 'A2'},
    {value: 'A3', label: 'A3'},
    {value: 'B1', label: 'B1'},
    {value: 'B2', label: 'B2'},
    {value: 'C1', label: 'C1'},
    {value: 'C2', label: 'C2'},
  ],
  examSub: [
    {value: '1', label: '科目一'},
    {value: '2', label: '科目二'},
    {value: '3', label: '科目三'},
    {value: '4', label: '科目四'},
  ],
  examSubStatus: [
    // examSub1Status - examSub2Status - examSub3Status - examSub4Status
    {value: '1', label: '未约考'},
    {value: '2', label: '已约考'},
    {value: '3', label: '未通过'},
    {value: '4', label: '已通过'},
  ],
  templateType: [
    {value: '1', label: '补考费报销服务'},
    {value: '2', label: '报名费会员服务'},
  ],
  policyFlowStatus: [
    // 赔付状态
    {value: '1', label: '未申请'},
    {value: '2', label: '已提交'},
    {value: '3', label: '已补偿'},
    {value: '4', label: '未通过'},
  ],
  orderFlowType: [
    {value: '1', label: '系统充值'},
    {value: '10', label: '会员服务结算'},
    {value: '20', label: '退款结算'},
    {value: '30', label: '补偿结算'},
  ],
  submitStatus: [
    {value: '1', label: '未提交'},
    {value: '2', label: '已提交'},
    {value: '3', label: '已通过'},
    {value: '4', label: '未通过'},
  ],
  submitStatus1: [
    {value: '1', label: '未提交'},
    {value: '2', label: '已提交'},
    {value: '3', label: '已打款'},
    {value: '4', label: '未通过'},
  ],
  contractStatus: [
    // 1 已加入  2 保障中  3 已完结（金额超限） 4 完结 5 已退出
    {value: '1', label: '已加入'},
    {value: '2', label: '保障中'},
    {value: '3', label: '金额超限'},
    {value: '4', label: '已完结'},
    {value: '5', label: '已退出'},
    // {value: '10', label: '审核中'},
    {value: '20', label: '已通过'},
    // {value: '30', label: '未通过'},
  ],
  returnType: [
    {value: '1', label: '否'},
    {value: '2', label: '是'},
  ],
  resitAuditStatus: [
    //  10审核中 20已通过 30未通过 2保障中 4服务完结
    {value: '10', label: '审核中'},
    {value: '20', label: '已通过'},
    {value: '30', label: '未通过'},
    {value: '2', label: '保障中'},
    {value: '4', label: '服务完结'},
  ],
  resitAuditStatusCopy: [
    //  10审核中 20已通过 30未通过 2保障中 4服务完结 1 已统筹  2 保障中 3 已完结（金额超限） 4 完结
    // {value: '1', label: '已服务'},
    // {value: '2', label: '保障中'},
    // {value: '3', label: '已完结（金额超限）'},
    // {value: '4', label: '服务完结'},
    {value: '10', label: '审核中'},
    {value: '20', label: '已通过'},
    {value: '30', label: '未通过'},
  ],
  enrollAuditStatusCopy: [
    {value: '1', label: '已服务'},
    {value: '2', label: '保障中'},
    {value: '3', label: '已完结（金额超限）'},
    {value: '4', label: '服务完结'},
    {value: '10', label: '审核中'},
    {value: '20', label: '已通过'},
    {value: '30', label: '未通过'},
  ],
  enrollAuditStatus: [
    {value: '0', label: '无服务'},
    {value: '10', label: '审核中'},
    {value: '20', label: '已通过'},
    {value: '30', label: '未通过'},
    {value: '2', label: '保障中'},
    {value: '4', label: '服务完结'},
  ],
  compensateAuditStatus: [
    {value: '1', label: '待打款'},
    {value: '2', label: '已打款'},
  ],
};
try {
  dict.AREA = window.SYS_AREA_INDEX[dict.SETTING.AREA_CODE].data;
} catch (err) {
  console.error('城市编码配置不正确！');
}
/**
 * 生产环境 使用 public目录下 setting.js声明的全局变量
 */
if (process.env.NODE_ENV === 'production') {
  Object.assign(dict.SETTING, window.ADMIN_SETTING);
}

dict.getValue = function (dict, value, key) {
  if (value == null || value == undefined || !dict) {
    return '';
  }
  if (typeof value == 'object') {
    value = value[key];
  }
  var item =
    dict.find((item) => {
      return item.value == value;
    }) || {};
  return item.label || '--';
};
dict.getData = function (dict, value) {
  var item = dict.find((item) => {
    return item.value == value;
  }) || {
    label: '--',
  };
  return (
    item || {
      label: '--',
    }
  );
};
export default dict;
