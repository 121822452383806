import moment from 'moment';
import Decimal from 'decimal.js';
import hasPermission from '@/utils/permission';
/**
 * @exports {$utils}
 */

export default {
  hasPermission(permissionList) {
    return hasPermission(permissionList);
  },
  /**
   *
   * @param {Number,String} B  - 任意数值
   * @param {String} unit  - 设置单位 可选值 B（默认） KB MD GB
   * @returns
   */
  humanSizeObj(B, unit = 'B', decimal = 0) {
    let unitDict = {
      B: 1,
      KB: 1024,
      MB: Math.pow(1024, 2),
      GB: Math.pow(1024, 3),
    };
    if (isNaN(Number(B))) {
      B = 0;
    }
    B = unitDict[unit] * B;
    let result = {
      value: 0,
      size: '',
    };

    if (B < unitDict.KB) {
      //小于0.1KB，则转化成B
      result.size = 'B';
      result.value = B.toFixed(2);
    } else if (B < unitDict.MB) {
      //小于0.1MB，则转化成KB
      result.size = 'KB';
      result.value = (B / unitDict.KB).toFixed(2);
    } else if (B < unitDict.GB) {
      //小于0.1GB，则转化成MB
      result.size = 'MB';
      result.value = (B / unitDict.MB).toFixed(2);
    } else {
      //其他转化成GB
      result.size = 'GB';
      result.value = (B / unitDict.GB).toFixed(2);
    }
    result.value = Decimal(result.value).toFixed(decimal);
    return result;
  },
  humanSize(B, unit = 'B', decimal = 1) {
    let obj = this.humanSizeObj(B, unit, decimal);
    return obj.value + obj.size;
  },
  downloadUrl(url, filename) {
    let eleLink = document.createElement('a');
    eleLink.style.display = 'none';
    if (filename) {
      eleLink.download = filename;
    }
    // 字符内容转变成blob地址
    // URL.createObjectURL(blob)会创建URL对象，返回一个下载文件的地址
    eleLink.href = url;
    // 触发点击
    document.body.appendChild(eleLink);
    eleLink.click();
    // 然后移除
    document.body.removeChild(eleLink);
  },
  downloadFile(res, fileName = '') {
    let blob = new Blob([res.data], {
      type: 'application/xlsx;charset=utf-8' /*application/xlsx // 这里写要下载的文件格式;charset=utf-8*/,
    });
    // 获取后端返回的文件名 --> 后端配合response.setHeader("Content-disposition", "attachment; filename=xxxx.xlsx") 设置的文件名
    // 注意: 有时候axios返回的res.headers里面可能不包含content-disposition字段
    //这是因为默认情况下，header只会暴露Cache-Control、Content-Language、Content-Type、Expires、Last-Modified、Pragma这6个字段
    // 此时需要后端设置response.setHeader("Access-Control-Expose-Headers", "Content-Disposition")将content-disposition字段暴露出去
    if (res.headers['content-disposition']) {
      fileName = res.headers['content-disposition'].split('=')[1];
      fileName = decodeURI(fileName);
    }

    let eleLink = document.createElement('a');
    // eleLink.download = '模板.xlsx' // 这里写的是下载文件的名称
    eleLink.download = fileName; // 这里是后端返回的文件名称
    eleLink.style.display = 'none';
    // 字符内容转变成blob地址
    // URL.createObjectURL(blob)会创建URL对象，返回一个下载文件的地址
    eleLink.href = URL.createObjectURL(blob);
    // 触发点击
    document.body.appendChild(eleLink);
    eleLink.click();
    // 释放URL对象
    URL.revokeObjectURL(eleLink.href);
    // 然后移除
    document.body.removeChild(eleLink);
  },
  /**
   * @param {string} name url中的key
   * @returns {string|null} 返回null或string
   */

  getUrlParams(name) {
    var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i'); //定义正则表达式
    var r = window.location.search.substr(1).match(reg);
    if (r != null) return unescape(r[2]);
    return null;
  },
  /**
   * 函数去抖
   * @param {function} func
   * @param {number} delay
   */
  debounce(func, delay) {
    var tId;
    return function () {
      var context = this;
      var arg = arguments;
      if (tId) clearTimeout(tId);
      tId = setTimeout(function () {
        func.apply(context, arg);
      }, delay);
    };
  },
  /**
   * 函数节流
   * @param {function} func
   * @param {number} delay
   */
  throttle(func, delay) {
    var timer = null;
    var startTime = Date.now();
    return function () {
      var curTime = Date.now();
      var remaining = delay - (curTime - startTime);
      var context = this;
      var args = arguments;
      clearTimeout(timer);
      if (remaining <= 0) {
        func.apply(context, args);
        startTime = Date.now();
      } else {
        timer = setTimeout(func, remaining);
      }
    };
  },
  /**
   * 当前是否进入全屏状态
   * @returns {Boolean}
   */
  isFullScreen() {
    return document.isFullScreen || document.mozIsFullScreen || document.webkitIsFullScreen;
  },
  /**
   * 全屏开关
   * @param {HTMLElement|undefined} element
   */
  setFullscreen(element) {
    if (!this.isFullScreen()) {
      this.fullScreen(true, element || document.documentElement);
    } else {
      this.fullScreen(false);
    }
  },
  /**
   * 全屏
   * @param {bolean} isOpen -是否全屏
   * @param {HTMLElement} target -要进入全屏的DOM
   */
  fullScreen(isOpen, target) {
    let dom = target;
    let open_list = ['requestFullscreen', 'mozRequestFullScreen', 'webkitRequestFullScreen', 'msRequestFullscreen'];
    let cancel_list = ['exitFullscreen', 'mozCancelFullScreen', 'webkitCancelFullScreen'];
    let fn = void 0;
    if (isOpen) {
      fn = open_list.find((n) => {
        return Boolean(dom[n]);
      });
      fn && dom[fn]();
    } else {
      fn = cancel_list.find((n) => {
        return Boolean(document[n]);
      });
      fn && document[fn]();
    }
  },
  /**
   * 获取区域对象
   * @param {string|number} areaId -区域ID
   * @returns {object|undefined} -返回区域对象
   */
  getArea(areaId) {
    if (!areaId) {
      return;
    }
    return window.SYS_AREA_INDEX[areaId];
  },
  /**
   * 获取区域对象路径
   * @param {string|number} areaId - 区域ID
   * @returns {array} -返回由大到小的地址名称 ['110000','110001']
   */
  getAreaFullId(areaId) {
    let arrPath = [];
    if (!areaId) {
      return arrPath;
    }
    let areaData = this.getArea(areaId);
    if (!areaData) {
      return arrPath;
    }
    while (areaData) {
      arrPath.unshift(areaData.data.code);
      areaData = areaData.parent;
    }
    return arrPath;
  },
  /**
   * 获取区域名称
   * @param {string|number} areaId - 区域ID
   * @returns {string}
   */
  getAreaName(areaId) {
    if (!areaId) {
      return '';
    }
    let areaData = this.getArea(areaId);
    if (!areaData) {
      return '';
    }
    return areaData.data.name || '';
  },
  /**
   * 获取区域名称路径
   * @param {string|number} areaId - 区域ID
   * @returns {array} -返回由大到小的地址名称 ['北京市','东城区']
   */
  getAreaNameFull(areaId) {
    let arrPath = [];
    if (!areaId) {
      return arrPath;
    }
    let areaData = this.getArea(areaId);
    if (areaData) {
      while (areaData) {
        arrPath.unshift(areaData.data.name);
        areaData = areaData.parent;
      }
    } else {
      let province = window.SYS_AREA2[areaId.slice(0, -4) + '0000'];
      let city = window.SYS_AREA2[areaId.slice(0, -2) + '00'];
      let zone = window.SYS_AREA2[areaId];
      if (zone && zone[0] !== '[') {
        return [zone]; // 不以[开始的地名包含省市前缀
      }
      if (province) {
        arrPath.push(province);
      }
      if (city) {
        arrPath.push(city.slice(1, -1));
      }
      if (zone) {
        arrPath.push(zone.slice(1, -1));
      }
    }

    return arrPath;
  },
  /**
   * 处理通用的异步返回
   * @param promise 调用/api/* 中函数返回的promise
   * @param vm vue的实例字段
   */

  resolveRequest(promise, vm, callback, errCallback) {
    promise
      .then((res) => {
        vm.loading = false;
        if (res.data.code == 0) {
          vm.$message.success(res.data.msg);
          vm.$emit('success');
          vm.$emit('end');
          typeof callback === 'function' ? callback() : '';
        } else {
          typeof errCallback === 'function' ? errCallback() : '';
          vm.$message.error(res.data.msg);
        }
      })
      .catch(() => {
        vm.loading = false;
        vm.$message.error('返回数据异常');
        typeof errCallback === 'function' ? errCallback() : '';
      });
  },
  /**
   * 格式化秒 到小时
   */
  formatDuration(timestamp) {
    // let duration = moment.duration(seconds, 'seconds');
    // let result = '';
    // if (duration.hours()) {
    //   result += duration.hours() + '小时';
    // }
    // if (duration.minutes()) {
    //   result += duration.minutes() + '分钟';
    // }
    // if (duration.seconds()) {
    //   result += duration.seconds() + '秒';
    // }
    // return result;
    if (timestamp) {
      return moment(timestamp, timestamp.toString().length == 10 ? 'X' : 'x').format('HH:mm:ss');
    }
    return '--';
  },
  /**
   *
   * @param {string|number} timestamp -10位或13位时间戳
   * @param {string} format -自定义格式 @see http://momentjs.cn/docs/#/parsing/string-format/
   */
  formatDate(timestamp, format) {
    format = format || 'YYYY-MM-DD';
    if (timestamp) {
      return moment(timestamp, timestamp.toString().length == 10 ? 'X' : 'x').format(format);
    }
    return '--';
  },
  /**
   *
   * @param {string|number} timestamp
   */
  formatDateFull(timestamp) {
    if (timestamp) {
      return moment(timestamp, timestamp.toString().length == 10 ? 'X' : 'x').format('YYYY-MM-DD HH:mm:ss');
    }
    return '--';
  },
  /**
   * 一维数组转tree结构
   * @param data 一维数组对象
   */
  arrToTree(data, idKey = 'id', pidKey = 'pid', topId = 0) {
    // 递归

    function getNode(id) {
      let node = [];
      for (let i = 0; i < data.length; i++) {
        if (data[i][pidKey] == id) {
          data[i].children = getNode(data[i][idKey]);
          node.push(data[i]);
        }
      }
      if (node.length == 0) {
        return;
      } else {
        return node;
      }
    }
    // 根节点
    return getNode(topId);
  },
};
