<template>
  <el-menu :default-active="$route.path" :unique-opened="true" router class="admin-slider-menu">
    <basic-navgator-item v-for="route in rootRoutes.children" :route="route" :key="route.path"></basic-navgator-item>
  </el-menu>
</template>
<script>
import {schHomeGetUntreatedTotal} from '@/api/index';
import {rootRoutes} from '@/router/routes';
import {Message} from 'element-ui';
import basicNavgatorItem from './basic-navgator-item';
export default {
  components: {basicNavgatorItem},
  data() {
    return {
      rootRoutes,
    };
  },
  created() {
    this.sysConfigGetUntreatedTotalHandler();
  },
  beforeDestroy() {
    clearTimeout(this.timer);
  },
  methods: {
    sysConfigGetUntreatedTotalHandler() {
      if (!localStorage.getItem('admin-ui-info-bx-school')) {
        clearTimeout(this.timer);
        return false;
      }
      schHomeGetUntreatedTotal()
        .then((res) => {
          if (res.data.code == 0) {
            if (res.data.data.endTime != this.$store.state.user.drvSchoolEndTime) {
              this.$store.dispatch('user/logout').then(() => {
                Message({
                  message: '登录超时',
                  type: 'info',
                  duration: 5 * 1000,
                });
                location.reload();
              });
            }
            this.$store.commit('dot/setState', res.data.data);
          } else {
            return false;
          }
        })
        .finally(() => {
          this.timer = setTimeout(() => {
            this.sysConfigGetUntreatedTotalHandler();
          }, 10000);
        });
    },
  },
};
</script>

<style></style>
