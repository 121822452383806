<template>
  <section class="admin-header">
    <div class="admin-header-left">
      <div class="header-brand">
        <!-- <img src="/static/logo.png" /> -->
        <!-- <div class="brand-title">Base</div> -->

        <i class="el-icon-s-help color-primary" style="font-size: 50px"></i>
      </div>
      <div class="brand-text">驾考无忧 - {{ $store.state.user.drvSchoolForShort }}</div>
    </div>
    <div class="admin-header-right">
      <el-dropdown size="medium" trigger="click">
        <div class="header-item admin-animate-link">
          {{ $store.state.user.drvSchoolForShort }}
          <i class="el-icon-arrow-down el-icon--right"></i>
        </div>
        <el-dropdown-menu slot="dropdown">
          <!-- <el-dropdown-item v-if="!$dict.SETTING.IS_MGT">
            <div @click="openEditInfo">编辑资料</div>
          </el-dropdown-item> -->
          <el-dropdown-item v-if="!$dict.SETTING.IS_MGT">
            <div @click="openEditPwd">修改密码</div>
          </el-dropdown-item>
          <el-dropdown-item :divided="!$dict.SETTING.IS_MGT">
            <div @click="logout">退出</div>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </section>
</template>

<script>
import utils from '@/utils/utils';
import {sysUserLogout} from '@/api/index';
export default {
  data() {
    return {
      isShowMessage: false,
    };
  },
  methods: {
    async openEditPwd() {
      let vNode = this.$createElement((await import('./edit-my-pwd')).default, {
        key: Math.random(),
      });
      this.$adminDialog(vNode, {
        title: '修改密码',
        width: '430px',
      });
    },
    async logout() {
      sysUserLogout();
      await this.$store.dispatch('user/logout');
      this.$router.replace({
        name: 'login',
      });
    },

    fullScreen() {
      utils.setFullscreen();
    },
  },
};
</script>

<style lang="scss">
.admin-bell-item {
  border-bottom: 1px solid $--border-color-light;
  display: flex;
  margin-bottom: 10px;
  padding-bottom: 5px;

  .text {
    small {
      color: $--color-text-placeholder;
    }
  }

  .img {
    width: 40px;
    margin-right: 5px;
    text-align: center;
    flex-shrink: 0;
  }

  &:last-child {
    border-bottom: none;
  }
}
</style>
