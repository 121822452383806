import './style.scss';
const SPACE_SIZE = {
  mini: 4,
  small: 8,
  medium: 16,
};

export default {
  functional: true,
  name: 'admin-space',
  props: {
    align: {
      type: String,
      default: 'start',
    }, // start | end |center |baseline
    direction: String, // x y
    size: {
      type: [String, Number],
      default: 'small',
    }, //mini | small | medium | number
    split: Object,
  },
  render: function render(h, content) {
    let children = content.children;

    if (children.length === 0) {
      return null;
    }
    let props = content.props;
    let itemStyle = {};
    itemStyle[`margin-${props.direction == 'y' ? 'bottom' : 'right'}`] = (SPACE_SIZE[props.size] || props.size) + 'px';
    var items = [];
    children.map((c, index) => {
      if (c.tag || (c.text && c.text.trim() !== '')) {
        items.push(
          <div style={index + 1 == children.length ? '' : itemStyle} class={'admin-space-item'}>
            {c}
          </div>,
        );
      }
    });
    return (
      <div
        style={content.data.staticStyle}
        class={[
          content.data.staticClass,
          'admin-space',
          props.direction == 'y' ? 'admin-space-vertical ' : '',
          `admin-space-align-${props.align} `,
        ]}>
        {items}
      </div>
    );
  },
};
